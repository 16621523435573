import * as React from "react"
import ModelCard from './ModalCard';
import * as classes from './Modal.module.css';

const Modal = (props) => {
	console.log(props);
	
	return (
		<>
			<div className={classes.backdrop}>

			<ModelCard className={classes.modal}>
			
				<button type='button' className={classes.closebutton} aria-label="Close video" onClick={props.onCancel}>close</button>
			
				<div className={classes.content}>
					<iframe src={"//player.vimeo.com/video/" + props.data.video + "?autoplay=1"} allowfullscreen="" data-ready="true" frameborder="0" width="640"></iframe>
				</div>
			
			</ModelCard>
			
			</div>
		</>
	);
};

export default Modal;