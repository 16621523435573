import * as React from "react"
import { Link } from "gatsby"
import { useEffect, useState } from "react";
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import SpeakWithAdvisor2 from '../components/ui/SpeakWithAdvisor2';
import Modal from '../components/ui/Modal';
import calendarsecondaryicn from "../assets/img/calendar-secondary-icn.svg";
import video1thumb from "../assets/img/video-thumb-1-1-5x.jpg";
import video2thumb from "../assets/img/video-thumb-2-1-5x.jpg";
import video3thumb from "../assets/img/video-thumb-3-1-5x.jpg";
import video4thumb from "../assets/img/video-thumb-4-1-5x.jpg";
import video5thumb from "../assets/img/video-thumb-5-1-5x.jpg";
import video6thumb from "../assets/img/video-thumb-6-1-5x.jpg";
import video7thumb from "../assets/img/video-thumb-7-1-5x.jpg";
import video8thumb from "../assets/img/video-thumb-8-1-5x.jpg";
import brochurecover from "../assets/img/aside-quickguide-img1-5x.png";
import checkmarkImage from "../assets/img/checkmark-tertiary-icn.svg";

const Medicare101 = ({ location }) => {


	useEffect (() => {
		window.MsCrmMkt = null;
		const dynFormScript = document.createElement("script");
		dynFormScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.77.1005";
		dynFormScript.async = false;
		document.body.appendChild(dynFormScript);
		
		const dynTrackScript = document.createElement("script");
		dynTrackScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.77.1005";
		dynTrackScript.async = false;
		document.body.appendChild(dynTrackScript);
	},[]);

	const [modalInfo, setModalInfo] = useState();

	const modalHandler = (event) => {
		const useragent = navigator.userAgent;
		let videoValue = "";
		
		if ( (useragent.indexOf("Trident/7.0") >= 0) || (useragent.indexOf("MSIE") >= 0) ) {
			videoValue = event.target.value;
		} else {
			videoValue = event.target.parentElement.value;
		}
		
		setModalInfo({
			video: videoValue
		});
	};

	const cancelHandler = () => {
		setModalInfo(null);
	};

	return (
		<Layout location={location} heading1="Welcome to the UVM Health Advantage Medicare Video Series" heading2="">

			<Seo title="UVM Health Advantage Medicare Video Series" meta={[{description: 'We created the UVM Health Advantage Medicare Video Series to address a wide range of common Medicare questions.'}]} bodyclass="medicare101 subpage" />
			
			{ modalInfo &&
				<div>
					<Modal data={modalInfo} onCancel={cancelHandler} />
				</div>
			}

			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
						<main>
							<p>Whether you&apos;re new to Medicare, or considering a move to a Medicare Advantage plan, we want to help you get the care and support you need to take on your health goals and challenges to live your best life. We created the Medicare videos below to address a wide range of common Medicare questions.</p>
							
							<div className="videowrapper">
							
								<button type="button" value="691899280" onClick={modalHandler} aria-label="Watch video">
									<img src={video1thumb} alt="MVP Medicare 101: What Is Medicare?" />
									<p>What is Medicare?</p>
								</button>
								<button type="button" value="691899331" onClick={modalHandler} aria-label="Watch video">
									<img src={video2thumb} alt="MVP Medicare 101: What do the different parts of Medicare mean?" />
									<p>What do the different parts of Medicare mean?</p>
								</button>
								<button type="button" value="691899414" onClick={modalHandler} aria-label="Watch video">
									<img src={video3thumb} alt="MVP Medicare 101: Do you need more than just Original Medicare?" />
									<p>Do you need more than just Original Medicare?</p>
								</button>
								
								<button type="button" value="691899483" onClick={modalHandler} aria-label="Watch video">
									<img src={video4thumb} alt="MVP Medicare 101: Medicare Advantage or Medicare Supplement?" />
									<p>Medicare Advantage or Medicare Supplement?</p>
								</button>
								<button type="button" value="691899538" onClick={modalHandler} aria-label="Watch video">
									<img src={video5thumb} alt="MVP Medicare 101: Does Medicare cover prescriptions?" />
									<p>Does Medicare cover prescriptions?</p>
								</button>
								<button type="button" value="691899606" onClick={modalHandler} aria-label="Watch video">
									<img src={video6thumb} alt="MVP Medicare 101: Are you covered when you travel?" />
									<p>Are you covered when you travel?</p>
								</button>
								
								<button type="button" value="691899691" onClick={modalHandler} aria-label="Watch video">
									<img src={video7thumb} alt="MVP Medicare 101: When can you enroll in or switch Medicare plans?" />
									<p>When can you enroll in or switch Medicare plans?</p>
								</button>
								<button type="button" value="691899787" onClick={modalHandler} aria-label="Watch video">
									<img src={video8thumb} alt="MVP Medicare 101: What coverage is right for you?" />
									<p>What coverage is right for you?</p>
								</button>

							</div>
						</main>
						
						<div className="formwrap">
							<h3>Request your FREE copy of the UVM Health Advantage Quick Guide to Medicare.</h3>
							<div className="form" dangerouslySetInnerHTML={{ __html: process.env.GATSBY_MEDICARE101_FORM }}></div>
						</div>

						<aside>
							<div className="medicareneeds">							
								<img src={brochurecover} alt="UVM Health Advantage Quick Guide to Medicare" width="287" height="326" />
								<p>Make sure you find the right Medicare plan for your needs.</p>								
								<ul>
									<li><img src={checkmarkImage} alt="checkmark" width="60" height="60" className="icon" />Highlight important dates and deadlines.</li>
									<li><img src={checkmarkImage} alt="checkmark" width="60" height="60" className="icon" />Read up on Medicare Parts A, B, C and D.</li>
									<li><img src={checkmarkImage} alt="checkmark" width="60" height="60" className="icon" />Learn what to do if you&rsquo;re not retiring.</li>
									<li><img src={checkmarkImage} alt="checkmark" width="60" height="60" className="icon" />Review important steps regarding Marketplace and Employer coverage.</li>
								</ul>
							</div>
						</aside>
					</div>
					<div className="ctas secondary">
						<div className="flexwrap">
							<div className="secondary-cta">
								<img src={calendarsecondaryicn} alt="Schedule a personal consultation" width="60" height="60" className="icon"/>
								<p className="head">
									<b>Schedule a personal consultation.</b>
								</p>
								<p>
									Search available phone, video, or in-person appointments, or request a meeting at a time and place that&rsquo;s convenient for you.
								</p>
								<div className="flexwrap_buttons">
									<a href="https://outlook.office365.com/owa/calendar/UVMHealthAdvantagePlanConsultationScheduling@mvphealthcare.onmicrosoft.com/bookings/" className="button external_button" target="_blank" rel="noreferrer" >Search Appointments</a>
									<Link to="/meet-with-us/request-consultation/" className="button secondary">Request a Consultation</Link>
								</div>
							</div>
						</div>
					</div>
					
					<SpeakWithAdvisor2 />
					
				</div>
			</div>
		</Layout>
	)
}

export default Medicare101
